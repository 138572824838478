import './App.css';
import HomeCom from './components/HomeCom';

function App() {
  return (
    <div className="App">
      
     <HomeCom/>
    </div>
  );
}

export default App;
